import { useMemo, useState } from 'react';

import { updateData, getIdentifiers, getFilters } from 'helpers/utils';

import { FormGroup, FormEditInput, SingleCheckbox, Checkbox, } from 'components';
import { EditHeader, GroupHeader, TopicList, SidebarSelect, RadioTopicList } from '..';
import { GENERAL_IS_IN_EFFECT, GENERAL_TAB_TITLE, GENERAL_TAB_CONTENT, GENERAL_FILTER_TABS, JOB_ROLE_KEY } from 'configs/legislation/legislation';
import { GeneralDetailsProps } from '../interfaces';
import styles from './General.module.scss'


const General = ({
  data,
  filters = [],
  onCheckboxChange,
  selectedIndex,
  onNotApplicable,
  ...props
}: GeneralDetailsProps) => {

  const [showExpirationDate, setShowExpirationDate] = useState(false);
  const [sustainabilityTopic, setSustainabilityTopic] = useState(filters.find((f) => f.label === 'topic')?.data || []);
  const [legislationTypes, setLegislationTypes] = useState(filters.find((f) => f.label === 'type')?.data || []);
  const [geographicalScope, setGeographicalScope] = useState(filters.find((f) => f.label === 'geographical_scope')?.data || []);
  const [productService, setProductService] = useState(filters.find((f) => f.label === 'product_service')?.data || []);
  const [jobRoleList, setJobRoleList] = useState(filters.find((f) => f.label === JOB_ROLE_KEY)?.data || []);
  const [issuingJurisdiction, setIssuingJurisdiction] = useState<string[]>(data?.issuing_jurisdiction?.map((i) => i.name) || []);


  /**
   * Get the topic roles and legislation topics.
   */
  useMemo(() => {
    setSustainabilityTopic(updateData(getFilters(filters, 'topic'), getIdentifiers(data.topic)));
    setLegislationTypes(updateData(getFilters(filters, 'type'), getIdentifiers(data.type)));
    setGeographicalScope(updateData(getFilters(filters, 'geographical_scope'), getIdentifiers(data.geographical_scope)));
    setProductService(updateData(getFilters(filters, 'product_service'), getIdentifiers(data.product_service)));
    setJobRoleList(updateData(getFilters(filters, JOB_ROLE_KEY), getIdentifiers(data.job_role_list)));
    setIssuingJurisdiction(data?.issuing_jurisdiction?.map((_i) => _i.name) || []);
  }, [data.geographical_scope, data?.issuing_jurisdiction, data.job_role_list, data.product_service, data.topic, data.type, filters]);


  if (!data.id) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <div data-content-wrapper {...props} data-hidden={!(selectedIndex === 1)} className={styles.root}>
      <FormGroup>
        <span data-required>Required Fields</span>
        <EditHeader title={GENERAL_TAB_TITLE} content={GENERAL_TAB_CONTENT} />
      </FormGroup>

      <FormGroup data-group="sustainability_topic">
        <GroupHeader required title="sustainability topic" content="Select all the sustainability topic(s) that the legislation applies to."/>
        <TopicList data={sustainabilityTopic} name="topic" />
      </FormGroup>

      <FormGroup data-form-inputs data-group="general_details">
        <FormEditInput name="pwc_territory" type='text' label='pwc territory' defaultValue={data.pwc_territory} required />
        <FormEditInput name="name_local" type='text' label='name of legislation in original language' defaultValue={data.name_local} required onNotApplicable={onNotApplicable} />
        <FormEditInput name="abbreviation" type='text' label='abbreviation' defaultValue={data.abbreviation} />
        <FormEditInput name="name_generic" type='text' label='Generic name of legislation' defaultValue={data.name_generic} required />
      </FormGroup>

      <FormGroup data-group="issuing_jurisdiction">
        <GroupHeader title="issuing jurisdiction" content="Select the country or jurisdiction that issued the legislation"/>
        <SidebarSelect
          name="issuing_jurisdiction_country"
          sidebarItems={GENERAL_FILTER_TABS}
          selectedNav=''
          selectedJurisdictions={issuingJurisdiction}
          onCheckboxChange={onCheckboxChange}
        />
      </FormGroup>

      <FormGroup data-group="job_role_list">
        <GroupHeader title="job role list" content="Select the relevant job role for this legislation."/>
        <TopicList data={jobRoleList} name="job_role_list"/>
      </FormGroup>

      <FormGroup data-group="legislation_type">
        <GroupHeader title="legislation type" content="Select the current status of the legislation."/>
        <TopicList data={legislationTypes} name="type"/>
      </FormGroup>

      <FormGroup data-group="geographical_scope">
        <GroupHeader title="geographical scope" content="Select the area where the legislation applies."/>
        <TopicList data={geographicalScope} name="geographical_scope"/>
      </FormGroup>

      <FormGroup data-group="is_in_effect">
        <GroupHeader required title="in effect" content="Select YES if legislation is currently in effect and NO if otherwise."/>
        <RadioTopicList roles={GENERAL_IS_IN_EFFECT.map((e) => {
          return {
            ...e,
            checked: e.label === 'Yes' && data.is_in_effect || e.label === 'No' && !data.is_in_effect
          };
        })} radioName='is_in_effect' />
      </FormGroup>

      <FormGroup data-group="applicable_from">
        <FormEditInput
          name="effective_date"
          type='date'
          label='applicable from'
          subLabel='Select the date as of when the legislation is or wil l be first applicable.'
          defaultValue={data.effective_date}
        />
        <Checkbox
          name="effective_until_toggle"
          inputId='effective_until_toggle'
          onChange={() => setShowExpirationDate((prev) => !prev)}
          data-show-expiration-date
        >
          Legislation has an expiration date
          </Checkbox>
        {showExpirationDate ? (
          <FormEditInput
            name="effective_until"
            type='date'
            label='applicable to'
            subLabel='Select the date as of when the legislation is no more applicable.'
            defaultValue={data.effective_until || ''}
            data-effective-until
          />) : null }
      </FormGroup>

      <FormGroup data-group="responsible_authority">
        <FormEditInput
          name="responsible_authority"
          type='text'
          label='responsible authority'
          subLabel='Specify which authority is responsible for the enforcement of this legislation.'
          defaultValue={data.responsible_authority}
          maxLength={50}
          rows={4}
          required
          isTextArea
        />
      </FormGroup>

      {/*  List of checkboxes */}
      <FormGroup data-group="relevant_product_group_service">
        <GroupHeader showCheckbox title="relevant product (group) or services" content="If applicable, specify whether the legislation applies to specific products and/ or services." required/>
        <ul>
          {productService.map((item: any, index) => (
            <li key={`product-${item.identifier}-${index}`}>
              <SingleCheckbox
                label={item.name}
                value={item.name}
                name={`product_service_data_${item.name.toLowerCase().replaceAll(' ', '_')}`}
                defaultChecked={item.checked}
              />
            </li>
          ))}
        </ul>
        {/* <SidebarSelect name="relevant_product_group_service" sidebarItems={PRODUCT_TABS} selectedNav='tech_products' /> */}
      </FormGroup>

      <FormGroup data-group="summary">
        <FormEditInput
          name="objective"
          type='text'
          label='objective of the legislation (summary)'
          subLabel='Short summary of the objective/ goal of the legislation.'
          defaultValue={data.objective}
          maxLength={150}
          rows={5}
          required
          isTextArea
        />
      </FormGroup>

      <FormGroup data-group="scope">
        <FormEditInput
          name="scope"
          type='text'
          label='scope of the legislation (summary)'
          subLabel='Short summary on the relevant definitions regarding the activities that trigger the applicability of the legislation.'
          defaultValue={data.scope || ''}
          maxLength={50}
          rows={5}
          required
          isTextArea
        />
      </FormGroup>

      <FormGroup data-group="responsible_party">
        <FormEditInput
          name="responsible_party"
          type='text'
          label='responsible party (summary)'
          subLabel='Short summary on who is defined within the legislation as the parties (i.e. economic operators) to whom the legislation applies.'
          defaultValue={data.responsible_party || ''}
          maxLength={150}
          rows={5}
          required
          isTextArea
        />
      </FormGroup>

      <FormGroup data-group="non_compliance">
        <GroupHeader required title="consequence of non-compliance" content="Select the relevant consequences that may follow from non-compliance with this legislation."/>
        <TopicList
          data={data.non_compliance_consequence?.map((c) => ({ ...c, checked: true, label: c.name }))}
          name="non_compliance_consequence"
        />
      </FormGroup>

      <FormGroup>
        <FormEditInput
          name="legislation_link"
          type='text'
          subLabel='Include a weblink to the legislation.'
          label='link to legislation'
          defaultValue={data.link}
          showCheckbox
          required
        />
      </FormGroup>

      <FormGroup>
        <FormEditInput
          name="additional_links"
          type='text'
          subLabel='Include Additional link for this legislation'
          label='Link to additional guidance'
          defaultValue={data.additional_links || ''}
        />
      </FormGroup>

      <FormGroup>
        <FormEditInput
          name="pwc_contact"
          type='text'
          subLabel='Who within PwC is the person of contact for this legislation.'
          label='relevant pwc contact'
          defaultValue={data.pwc_contact[0]?.email || ''}
        />
      </FormGroup>
    </div>
  );

};

export default General;
