import { useLayoutEffect, useEffect, useRef, useMemo } from 'react';

import { DialogProps } from './Dialog.interface';
import styles from './Dialog.module.scss';

/**
 * Renders the Dialog component.
 *
 * @param {DialogProps} props - The component props.
 * @returns {React.ReactNode} The rendered component.
 */

const Dialog = ({
  id = 'dialog',
  isOpen = false,
  onClose,
  onOpen,
  children,
  ...props
}: DialogProps) => {

  const dialogRef = useRef<HTMLDialogElement>(null);


  /**
   * Handle closing and opening of modal.
   */
  useEffect(() => {
    if (isOpen && !dialogRef.current?.open) {
      dialogRef.current?.showModal();
      onOpen();

      // Focus on dialog, and blur afterward, so close button is not highlighted by default.
      dialogRef.current?.focus()
      window.setTimeout(() => dialogRef.current?.blur(), 1000 / 60);
    } else if (!isOpen && dialogRef.current?.open) {
      dialogRef.current.close();
      onClose();
    }
  }, [isOpen, onClose, onOpen]);


  /**
   * Listen for native open/close based on `open`-attribute.
   * E.g. triggered from outside of React by targeting the modal via the `id`.
   */
  useEffect(() => {
    const currentRef = dialogRef.current;

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'open') {
          if (currentRef?.open) {
            onOpen();
          } else {
            onClose();
          }
        }
      });
    });

    if (currentRef) {
      observer.observe(currentRef, { attributes: true });
    }

    return () => observer.disconnect();
  }, [dialogRef, onClose, onOpen]);


  /**
   * Attache `Escape` key listener.
   */
  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (isOpen && e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  }, [isOpen, onClose]);

  /**
   * Handle closing of modal when clicked outside of it.
   */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current?.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);


  return (
    <dialog id={id} ref={dialogRef} className={styles.root} {...props}>
      {children}
    </dialog>
  );

};

export default Dialog;
