import { FormEditInput, FormGroup } from "components";

interface CreateTab {
  selectedIndex: number,
  direction: string
}


const ClientDetails = ({ selectedIndex, direction }: CreateTab) => {
  return (
    <section data-hidden={!(selectedIndex === 1)} data-create-content>
      <FormGroup data-form-inputs data-create-group="client_details" data-direction={direction}>
        <FormEditInput
          name="name"
          type='text'
          label='project name'
          defaultValue={''}
          placeholder='Type your project name here'
          required
        />
        <FormEditInput
          name="domain"
          type='text'
          label='user email domain'
          subLabel={`Set up the client email domain, so that all the users within this domain get access to this client <br/>environment <strong data-email-domain>eg. @pwc.com</strong>`}
          defaultValue={''}
          placeholder='@pwc.com'
          data-user-email-domain
          required
        >
        <span>@</span>
        </FormEditInput>
        <FormEditInput
          name="starting_date"
          type='date'
          label='starting date of engagement'
          defaultValue={''}
          data-effective-until
        />
        <FormEditInput
          name="description"
          type='text'
          label='project description'
          defaultValue={''}
          placeholder='Write a project description here'
          maxLength={150}
          rows={3}
          isTextArea
        />
      </FormGroup>
    </section>
  );

};


export default ClientDetails;
