import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { uuid } from '@grrr/utils';

import { getFormValues } from 'helpers/utils';
import { formatProjectPayload } from 'helpers/projects/projects';
import { applyFilter } from 'hooks/reducers/helpers';

import { BackLink, Button, ButtonSet, Form, Loader, Modal, Tabs, TopContent } from 'components';
import { ClientEditDetailsTab, AdminSetupEditTab, ContentConfigurationEditTab } from './EditTabs';
import EditRoleContent from 'pages/Legislation/Admin/EditContent/EditRoleContent';

import { useQueryApi } from 'hooks/index';
import { useLegislationContext, useNavigatorContext, useUserContext } from 'contexts';

import { DEFAULT_APPROVED_FILTER } from 'configs/legislation/legislation';
import { PROJECT_DETAILS_TABLIST } from 'configs/project/project';
import { GET_CLIENT_PROJECT_DETAILS, UPDATE_CLIENT_PROJECT } from 'configs/api-endpoints';
import { Project, SelectedAttributes } from './EditTabs/interfaces';
import { Category, Filter, Legislation } from 'hooks/interfaces/legislation.interface';
import styles from './ProjectEdit.module.scss';

const PROJECT_EDIT_FORM_ID = 'project-edit-form-1';
const LEGISLATION_DETAILS_ROLE_CONTENT_MODAL_ID = 'legislation_details_role_content_client-1';

const TAB_TITLE: { [key: number]: string } = {
  '1': 'View and edit the client environment.',
  '2': 'Invite pwc admin users to get access and modify the client environment and content.',
  '3': 'Select the attributes that match the client project objectives from the general Sustainability Legislation Navigator database, to only select the legislation pieces that are relevant to the client and match their needs.',
}


const emptyAttributes: SelectedAttributes = {
  type: { label: 'Type', data: [] },
  topic: { label: 'Topic', data: [] },
  issuing_jurisdiction: { label: 'Issuing Jurisdiction', data: [] },
  geographical_scope: { label: 'Geographical Scope', data: [] },
  job_roles: { label: 'Job Roles', data: [] },
  product_service: { label: 'Product/Service', data: [] },
}

const emptyFilters: Filter[] = [
  { name: 'Type', label: 'type', data: [] },
  { name: 'Topic', label: 'topic', data: [] },
  { name: 'Issuing Jurisdiction', label: 'issuing_jurisdiction', data: [] },
  { name: 'Geographical Scope', label: 'geographical_scope', data: [] },
  { name: 'Job Roles', label: 'job_roles', data: [] },
  { name: 'Product/Service', label: 'product_service', data: [] },
];


const ProjectDetails = () => {

  const tabRef = useRef<HTMLUListElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const { projectId } = useParams();
  const id = useMemo(() => uuid(), []);

  const { state, stateDispatch, stateActions } = useLegislationContext();
  const { user } = useUserContext();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [currentProject, setCurrentProject] = useState<Project>();
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});
  const [teamMembers, setTeamMembers] = useState<string[]>([]);
  const [legislations, setLegislations] = useState<Legislation[]>(state.legislations);
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>(emptyFilters as Filter[]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentEditCard, setCurrentEditCard] = useState<Legislation>();

  const { get: getProjectDetails } = useQueryApi({
    ...GET_CLIENT_PROJECT_DETAILS,
    endpoint: `${GET_CLIENT_PROJECT_DETAILS.endpoint}${projectId}`
  })
  const { patch: updateProjectQuery } = useQueryApi({
    ...UPDATE_CLIENT_PROJECT,
    endpoint: `${UPDATE_CLIENT_PROJECT.endpoint}${projectId}/`
  });
  const { data: projectData, error: projectError, isLoading: isPLoading } = getProjectDetails(null);
  const { data: updatedProject, mutate: updateProject, error: updateError, isPending, isSuccess } = updateProjectQuery();


  useEffect(() => {
    if (projectData && !isPLoading) {
      setCurrentProject(projectData);
    }
  }, [isPLoading, projectData]);

  useEffect(() => {
    setSelectedFilters(state.filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


    /**
   * Mange the team members added to the project payload
   * @param teamMembers
   */
    const updateTeamMembers = (teamMembers: string[]) => {
      setTeamMembers(teamMembers);
    };


  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    setSelectedIndex(Number(index));
  };


  /**
   * Update the filters when the user selects a filter.
   * @param filters
   * @param event
  */
  const updateFilters = (event: any, isInputChecked: boolean) => {
    if (!event?.target || event?.target?.name === 'search_legislation') return;

    const input = event.target.previousElementSibling?.querySelector('input');
    const isCustomCheckbox = input?.dataset.customCheckbox;
    const category = isCustomCheckbox ? 'issuing_jurisdiction' : event.target.dataset.category;
    const identifier = isCustomCheckbox && input
      ? state?.filters?.find(f => f.label === category)?.data?.find(d => d.name === input?.value)?.identifier
      : event.target.dataset.identifier;

    if (category === "issuing_jurisdiction") {
      setSelectedFilters((prev: any) => {
        return updateFilterData(identifier, category, isInputChecked, prev);
      });
    }


    // for the default checkboxes
    if (event.target.type === 'checkbox' && !isCustomCheckbox && identifier) {
      setSelectedFilters((prev: any) => {
        return updateFilterData(identifier, category, event.target.checked, prev);
      })
      return;
    }
  };


  /**
   * Update the filter data when the user selects a filter.
   * @returns Filters
   */
  const updateFilterData = (id: string, category: string, isChecked: boolean, filters: Filter[]) => {
    return filters?.map((filter: Filter) => {
      if (filter?.label === category) {
        return {
          ...filter,
          data: filter.data.map((data) => {
            if (data.identifier === id) {
              return {
                ...data,
                checked: isChecked,
                is_approved: isChecked
              }
            }
            return data;
          })
        }
      }

      return filter;
    });
  }


/**
 * Update the legislations when the selected filters change.
 */
useEffect(() => {
  // setLegislations(applyFilter(state.legislations, selectedFilters));
}, [selectedFilters, state.legislations]);

  /**
   * Handle Form input changes.
   */
  const onFormInputChange = (event: any, isChecked: boolean) => {
    if (!formRef.current) return;

    // Update filters
    const formData = Object.fromEntries(new FormData(formRef.current).entries());

    // Update form values
    setFormValues((prev: any) => ({
      ...prev,
      ...formData,
      team_members: teamMembers
    }));
    updateFilters(event, isChecked);
  };

  /**
   * Update the form values when the special checkbox changes.
   * @param e
   * @param isChecked
   */
  const onCheckboxChangeHandler = (e: any, isChecked: boolean) => {
    onFormInputChange(e, isChecked);
  }


  /**
   * Handle form submission.
   */
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
    if (!formValues) return;

    const payload = formatProjectPayload(formValues, legislations, teamMembers);
    // Update the project
    updateProject({ ...payload, is_published: true });
  };


  /**
   * Save Legislation for future editing
   * @param e
   */
  const onSaveProjectAsDraftClickHandler = (_e: any) => {
    const payload = formatProjectPayload(formValues, legislations, teamMembers);
    // Patch the data to the API
    if (Object.keys(payload).length > 0) {
      updateProject({ ...payload, is_published: false });
    }
  };

  /**
   * Update form values when team members changes.
   */
  useEffect(() => {
    if (isFormUpdated) {
      onFormInputChange(undefined, false);
      setIsFormUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormUpdated]);


  useEffect(() => {
    if (state.filteredLegislations?.length) {
      setCurrentEditCard(state.filteredLegislations[0]);
    }
  }, [state.filteredLegislations]);

  const alertContent = 'Please be aware that this is still under development and may be missing some features/functionalities.'


  return (
    <Fragment>
      <TopContent isDetails data-details-page showAlert content={alertContent}>
        <BackLink>Back</BackLink>
        <div className={styles.root__topcontent}>
          <h3>Edit project</h3>
        </div>
        <p>View and edit the client environment.</p>

        {/* Tabs */}
        <Tabs
          tabSelected={selectedIndex}
          options={PROJECT_DETAILS_TABLIST.map((tab) => tab.label)}
          onTabClick={tabClickHandler}
          data-tabs
          align="left"
          type="tabs"
          ref={tabRef}
        >
          <ButtonSet data-btn-set>
            <Button
              variation='cancel'
              size="small"
              type='button'
              url={`/projects/${projectId}/details`}
              title={`Cancel changes for: ${(projectData)?.name || ''}`}
            >
              Cancel
            </Button>
            <Button
              variation='secondary'
              size="small"
              type='button'
              title={`Save changes for: ${(projectData)?.name || ''}`}
              onClick={onSaveProjectAsDraftClickHandler}
            >
              Save draft
            </Button>
            <Button
                variation='primary'
                size="small"
                type='submit'
                aria-controls={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
                form={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
                title={`Publish: ${(projectData)?.name || ''}`}
              >
                Publish
              </Button>
          </ButtonSet>
        </Tabs>
      </TopContent>

      <article className={styles.root__article} data-content>
        <p>{TAB_TITLE[selectedIndex]}</p>
        <Form
          id={`${PROJECT_EDIT_FORM_ID}-${projectId}`}
          data={{}}
          onSubmit={formSubmitHandler}
          onChange={onFormInputChange}
          ref={formRef}
        >
          {projectData ? (
            <Fragment>
              <ClientEditDetailsTab
                selectedIndex={selectedIndex}
                project={projectData}
              />
              <AdminSetupEditTab
                selectedIndex={selectedIndex}
                project={projectData}
                updateMembers={updateTeamMembers}
                setIsFormUpdated={setIsFormUpdated}
              />
              <ContentConfigurationEditTab
                selectedIndex={selectedIndex}
                project={projectData}
                filters={state.filters}
                legislations={state.filteredLegislations}
                setCurrentEditCard={setCurrentEditCard}
                setIsEditing={setIsEditing}
                dispatch={stateDispatch}
                actions={stateActions}
                selectedFilters={selectedFilters}
                onCheckboxChange={onCheckboxChangeHandler}
              />
            </Fragment>
          ) : (
            <Loader data-medium />
          )}
        </Form>
      </article>

      {/* Edit modal */}
      <Modal
        id={LEGISLATION_DETAILS_ROLE_CONTENT_MODAL_ID}
        isOpen={isEditing}
        onOpen={() => setIsEditing(true)}
        onClose={() => setIsEditing(false)}
        data-variation="drawer"
      >
        {currentEditCard?.identifier && isEditing ? (
          <EditRoleContent
            user={user}
            filters={state.filters}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            legislation={currentEditCard as any}
            data-drawer
          />
        ) : null }
      </Modal>
    </Fragment>
  );

};

export default ProjectDetails;
