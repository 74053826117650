import { Children, useEffect, useMemo, useState } from "react";
import { Client, EditComponentProps } from "../interfaces";

import { ProfileSearch, TeamMember } from "components/index";
import { UserProfile } from "hooks/interfaces";
import styles from './Admin.module.scss';

const AdminSetupEditTab = ({
project,
  selectedIndex,
  updateMembers,
  setIsFormUpdated,
}: EditComponentProps & {
  updateMembers: (members: string[]) => void;
  setIsFormUpdated: (isUpdated: boolean) => void;
}) => {

  const [clientMembers, setClientMembers] = useState<Client[] | []>(project.client_members || []);

  useEffect(() => {
    setClientMembers(project.client_members || []);
    updateMembers([...project.client_members.map((t) => t.identifier)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.client_members]);

  /**
   * Group members based on their roles.
   */
  const groupedMembers = useMemo(() => {
    const uniqueRoles = Array.from(new Set(['approver', 'preparer']));
    return uniqueRoles.map((role: string | undefined) => {
      return {
        role,
        subtitle: role === 'approver'
          ? 'Master admins can do any content modifications within a client\'s environment and also publish the changes to the client\'s view.'
          : 'General admins can modify the content within a client\'s environment. ',
        members: clientMembers.filter((member: Client) => member.groups[0] === role)
      }
    });
  },[clientMembers]);

  /**
   * Handles new client member added.
   * @param member
   */
  const onNewClientMemberAdded = (member: any) => {
    if (!clientMembers.find((m: Client) => m.email === member.email)) {
      setClientMembers([...clientMembers, member]);
      updateMembers([...clientMembers.map((t) => t.identifier), member.identifier]);
      setIsFormUpdated(true);
    }
  };

  /**
   * Handles client member removed.
   */
  const onClientMemberRemoved = (member: any) => {
    const updatedMembers = clientMembers.filter((m: Client) => m.email !== member.email);
    setClientMembers(updatedMembers);
    updateMembers([...updatedMembers.map((t) => t.identifier)]);
    setIsFormUpdated(true);
  }


  return (
    <section className={styles.root__admin_setup} data-hidden={!(selectedIndex === 2)} data-create-content>
      <header data-header>
        <ProfileSearch
          id="team_members"
          label="invite admins"
          subLabel=""
          placeholder="Assign project admins through email"
          disabledEntries={[]}
          scrollOnFocus={true}
          onSelect={onNewClientMemberAdded}
        />
      </header>
      <div data-row>
        <span data-title>invited admins<small>{project.client_members?.length || 0}</small></span>
      </div>
      <article data-div-group>
        {Children.toArray(groupedMembers.map((group: any) => (
          <div className={styles.root__team} data-empty={project.client_members.length === 0}>
            <h6>{group.role || 'preparer'} Admins</h6>
            <span data-subtitle>{group.subtitle}</span>
            {group.role !== 'approver' ? (<br/>) : null}
            <span>&nbsp;</span>
            <ol
              hidden={false}
              aria-live="polite"
              aria-label="Admin Users"
            >
              {Children.toArray(group.members?.map((member: Client, index: number) => (
                <li
                  className={styles.root__person}
                  data-index={index}
                  tabIndex={0}
                >
                  <TeamMember
                    data={member as unknown as UserProfile}
                    query={member.email}
                    disabled={false}
                    onRemove={() => onClientMemberRemoved(member)}
                  />
                </li>
              )))}
            </ol>
          </div>
        )))}
      </article>
    </section>
  );

};

export default AdminSetupEditTab;
