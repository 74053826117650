import { Children, useState } from 'react';
import { Button, IconComponent, ProfileSearch, TeamMember } from "components";

import { UserProfile } from 'hooks/interfaces';
import styles from '../Projects.module.scss';

interface CreateTab {
  selectedIndex: number,
  updateMembers: (members: string[]) => void;
  setIsFormUpdated: (isUpdated: boolean) => void;
}

const AdminSetup = ({ selectedIndex, updateMembers, setIsFormUpdated }: CreateTab) => {
  const [teams, setTeams] = useState<UserProfile[]>([]);


  /**
   * Handles team member addition.
   * @param user
   * @returns
   */
  const onTeamMemberAddHandler = (user: UserProfile) => {
    if (teams.find((team) => team.identifier === user.identifier)) {
      alert('User already exists');
      return;
    }

    setTeams((prev) => [...prev, user]);
    updateMembers([...teams.map((t) => t.identifier), user.identifier]);
    setIsFormUpdated(true);
  };


  /**
   * Handle team member removal.
   * @param user
   */
  const onTeamMemberRemoveHandler = (user: UserProfile) => {
    const newTeams = teams.filter((team) => team.identifier !== user.identifier);
    setTeams(newTeams);
  };


  return (
    <section data-hidden={!(selectedIndex === 2)} data-create-content>
      <ProfileSearch
        id="team_members"
        label="Admin Users"
        subLabel="Invite pwc admin users to get access and modify the client environment and content."
        placeholder="Assign project admins through email"
        disabledEntries={[]}
        scrollOnFocus={true}
        onSelect={onTeamMemberAddHandler}
      />
      <div className={styles.root__team} data-empty={teams.length === 0}>
        <ol
          hidden={false}
          aria-live="polite"
          aria-label="Admin Users"
        >
          {teams.length ? Children.toArray(teams.map((profile: UserProfile, index: number) => {
            return (
              <li
                className={styles.root__person}
                data-index={index}
                tabIndex={0}
              >
                <TeamMember
                  data={profile}
                  query={profile.email}
                  disabled={false}
                  onRemove={onTeamMemberRemoveHandler}
                />
              </li>
            )
          })) : <span>Please add team members using the search input above...</span>}
        </ol>

      </div>
    </section>
  );

};

export default AdminSetup;
