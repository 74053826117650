import { Legislation } from "hooks/interfaces";

export const formatProjectPayload = (data: any, legislations: Legislation[], teamMembers: any[]) => {
  // Get payload data
  return {
    name: data.name,
    description: data.description,
    domain: data.domain,
    starting_date: data.starting_date,
    team_members: teamMembers,
    legislation_list: legislations.map((legislation: Legislation) => legislation.identifier),
  };
};
