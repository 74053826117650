import { Children, useMemo, useState, forwardRef, useEffect } from 'react'
import { uuid } from '@grrr/utils';
import { isLeftKey, isRightKey, isDownKey } from 'helpers/utils';
import { IconComponent } from 'components';

import { TabsProps } from './Tabs.interface';
import styles from './Tabs.module.scss';


const Tabs = forwardRef<HTMLUListElement, TabsProps>(({
  tabSelected,
  onTabClick,
  align = 'auto',
  type = 'pages',
  options = [],
  showLastTabIcon = false,
  children,
}: TabsProps, ref) => {

  const id = useMemo(() => uuid(), []);
  const [selectedIndex, setSelectedIndex] = useState<number>(tabSelected || 1);


  /**
   * Update selected index when tabSelected changes.
   */
  useEffect(() => {
    if (tabSelected) {
      setSelectedIndex(tabSelected);
    }
  }, [tabSelected]);


  /**
   * Node index getter.
   */
  const getIndexFromNode = (node: HTMLElement) => {
    const attributeValue = node.getAttribute('data-filter-index');
    return attributeValue ? parseInt(attributeValue, 10) : 0;
  };


  /**
   * Handle tab click.
   */
  const tabClickHandler = (e: any) => {
    const index = getIndexFromNode(e.currentTarget);
    setSelectedIndex(index);
    e.preventDefault();

    if (typeof onTabClick === 'function') {
      const currentIndex = Number.parseFloat(e.target.closest('a')?.dataset.filterIndex);
      onTabClick(currentIndex);
    }
  };


  /**
   * Handle tab key down.
   */
  const tabKeyDownHandler = (e: any) => {
    const target = e.currentTarget;

    // Handle `left` key event.
    if (isLeftKey(e) && selectedIndex > 0) {
      target.parentNode.previousElementSibling.firstElementChild.focus();
      setSelectedIndex(selectedIndex - 1);
      e.preventDefault();
    }

    // Handle `right` key event.
    if (isRightKey(e) && selectedIndex < (options.length - 1)) {
      target.parentNode.nextElementSibling.firstElementChild.focus();
      setSelectedIndex(selectedIndex + 1);
      e.preventDefault();
    }

    // Handle `down` key event and focus on the current tab.
    if (isDownKey(e)) {
      window.setTimeout(() => document.querySelector(target.getAttribute('href')).focus(), 0);
      e.preventDefault();
    }
  };


  /**
   * Handles setting the the selected tab to make it active
   * @param index
   * @param selectedIndex
   * @returns
   */
  const isSelected = (index: number, selectedIndex: number) => {
    return type === 'tabs'
      ? (index + 1) === selectedIndex
      : ((index + 1) === selectedIndex || selectedIndex >= (index + 1))
  }

  return (
    <ul className={styles.root__tablist} role="tablist" data-tabs ref={ref} data-align={align} data-type={type}>
      {Children.toArray(options.map((tab, index) => (
        <li role="presentation">
          <a
            className={styles.root__tablistlink}
            role="tab"
            data-filter-index={index + 1}
            href={`#tabpanel-${id}-${index}`}
            id={`tab-${id}-${tab}`}
            aria-selected={isSelected(index, selectedIndex)}
            tabIndex={(index + 1) === selectedIndex ? 0 : -1}
            onClick={tabClickHandler}
            onKeyDown={tabKeyDownHandler}
          >
            <span>
              {showLastTabIcon ? <IconComponent name="ViewOutlineIcon" /> : null}
              {tab}
            </span>
          </a>
        </li>
      )))}

      <div className={styles.root__actions}>
        {children}
      </div>
    </ul>
  );

});

export default Tabs;
