import { useMemo, useRef, useState } from 'react';
import { uuid } from '@grrr/utils';

import { useLegislationContext, useAppStateContext } from 'contexts';
import { Button, ButtonSet, Form, Accordion, AccordionItem, FormGroup, FormEditInput } from 'components';

import {
  REVIEW_STATE,
} from 'configs/legislation/legislation';

import { EditProps } from './TabPages/interfaces';
import styles from './Edit.module.scss';
import { Legislation } from 'hooks/interfaces';
import { FilterOption } from 'hooks/interfaces/legislation.interface';

const JOB_ROLES = 'job_roles';

const EditRoleContent = ({   legislation,
  user,
  isEditing,
  setIsEditing,
  filters,
  ...props
}: EditProps) => {
  // Form reference
  const formRef = useRef<HTMLFormElement | null>(null);

  // Unique IDs to be used
  const generalFormId = useMemo(() => uuid(), []);
  const id = useMemo(() => uuid(), []);
  const roleSpecificAccordionId = useMemo(() => uuid(), []);

  const { state, stateDispatch, stateActions } = useLegislationContext();
  const { showToast } = useAppStateContext();

  const [roles, setRoles] = useState<FilterOption[]>(filters.find((f: any) => f.label === JOB_ROLES)?.data || []);
  const [activeKeys, setActiveKeys] = useState<string[]>([roles.at(0)?.identifier || '']);

  /**
   * Accordion CLick
   */
  const onAccordionClick = (activeKeys: string[]) => {
    setActiveKeys(activeKeys);
  };


  /**
   * Handle form submission.
   */
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
  };

  /**
   * Handle Form input changes.
   */
  const onFormInputChange = (e: any, isChecked?: boolean) => {
    if (!formRef.current || !isEditing) return;
  };

  /**
   * Show the correct button
   */
  const editButton = () => {
    return (
      <ButtonSet className={styles.root__buttongroup}>
        <Button
          variation='cancel'
          size="small"
          type='button'
          title={`Save changes for: ${(legislation as Legislation)?.name_generic || ''}`}
          onClick={() => setIsEditing(false)}
        >
          Cancel
        </Button>

        <Button
          variation='primary'
          size="small"
          title={`${user.is_approver && legislation.preparation_state === REVIEW_STATE
            ? 'Approved for:'
            : 'Send '}'${(legislation as Legislation)?.name_generic || '' }' ${user.is_approver
              ? '' : 'for review'
            }`
          }
          data-user-type={user.is_approver && legislation.preparation_state === REVIEW_STATE ? 'approver' : 'preparer'}
          type='submit'
        >
          Save
        </Button>
      </ButtonSet>
    );
  };

  return (
    <section
      className={styles.root}
      hidden={!isEditing}
      data-main-content
      data-edit-content
      data-role-specific
      {...props}
    >
      <Form
        id={generalFormId}
        data={{}}
        onSubmit={formSubmitHandler}
        onChange={onFormInputChange}
        ref={formRef}
        {...props}
      >
        <header className={styles.root__header}>
          <div className={styles.root__headertop}>
            <h3>Client specific content</h3>
            {editButton()}
          </div>

          <h4>{legislation.name_generic === 'nan' ? legislation.name_local : legislation.name_generic}</h4>
          <p>Add client role-specific content for this legislation. This will appear on the equivalent client-defined user roles.</p>
        </header>

          <p>This part is still under development. (EditRoleContent.tsx) &nbsp; ¯\_(ツ)_/¯</p>

        {/* <Accordion
            multiple={false}
            activeKeys={activeKeys}
            accordionId={`role-specific-accordion-${roleSpecificAccordionId}`}
            onClick={onAccordionClick}
          >
            {roles.map((role, index) => (
              <AccordionItem
                key={`role_${role?.identifier}_${index}`}
                itemKey={role?.identifier}
                contentTitle={role?.name}
                isLarge
                data-is-editing
              >
                <FormGroup data-group={`role_${role?.name || ''}_what_group`} data-role-specific>
                  <FormEditInput
                    name={`why_${role?.name?.toLowerCase() || ''}`}
                    type='text'
                    subLabel={`Why is this legislation relevant to <strong>${role?.name || ''}?</strong>`}
                    defaultValue={''}
                    maxLength={300}
                    rows={5}
                    isTextArea
                  />
                </FormGroup>

                <FormGroup data-group={`role_${role?.name || ''}_why_group`} data-role-specific>
                  <FormEditInput
                    name={`what_${role?.name?.toLowerCase() || ''}`}
                    type='text'
                    subLabel={`Why does <strong>${role?.name || '' }</strong> need to do?`}
                    defaultValue={''}
                    maxLength={300}
                    rows={5}
                    isTextArea
                  />
                </FormGroup>

                <FormGroup data-group={`role_${role?.name}_example_group`} data-role-specific>
                  <FormEditInput
                    name={`example_${role?.name.toLowerCase()}`}
                    type='text'
                    subLabel={`Example of <strong>${role?.name}</strong> compliance.`}
                    defaultValue={''}
                    maxLength={100}
                    rows={3}
                    isTextArea
                  />
                </FormGroup>
              </AccordionItem>
            ))}
        </Accordion> */}
      </Form>

    </section>
  );

};

export default EditRoleContent;
