import { Children } from 'react';
import { Pill } from 'components';
import styles from './TopList.module.scss';

interface TopicListProps {
  data: any;
  name: string;
  onChange?: (e: any) => void;
  isAttentionPoint?: boolean;
  [key: string]: any;
}

const TopicList = ({ data = [], isAttentionPoint = false, name = '', onChange = undefined, ...props }: TopicListProps) => {
  const optionList = isAttentionPoint ? data.roles : data;

  return (
    <ul {...props}>
      {optionList.map((role: any, index: number) => (
        <Pill
          key={`role-${role.label || role.name}-${index}`}
          data={role}
          pillName={name}
          onChange={onChange}
          {...props}
        />
      ))}
    </ul>
  );

};

export const RadioTopicList = ({ roles, radioName }: { roles: any[], radioName: string }) => {

  return (
    <ul className={styles.root__radiotopiclist}>
      {Children.toArray(roles.map((_r) => (
        <li className={styles.root__radiotopiclist__item}>
          <input
            type="radio"
            id={_r.identifier}
            defaultChecked={_r.checked}
            data-identifier={_r.identifier}
            name={radioName}
            value={_r.name}
          />
          <label htmlFor={_r.identifier} aria-label={_r.labels}>{_r.label}</label>
        </li>
      )))}
    </ul>
  );
};

export default TopicList;
