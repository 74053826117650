import { useState } from 'react';
import { setupGetInstanceId } from 'helpers/utils';
import { Checkbox } from 'components';

import styles from './GroupHeader.module.scss';

interface GroupHeaderProps {
  title?: string;
  content?: string;
  showCheckbox?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  [key: string]: any;
}
const getInstanceId = setupGetInstanceId();

// GroupHeader
const GroupHeader = ({
  title,
  content,
  required = false,
  showCheckbox = false,
  children,
  ...rest
}: GroupHeaderProps) => {
  const [disabledInput, setDisabledInput] = useState<boolean>(false);

  return (
    <div className={styles.root} data-group-header data-required={required} {...rest} data-group-disabled={disabledInput}>
      {title ? (
        <h6 data-group-title>
          <span dangerouslySetInnerHTML={{ __html: title }} data-required={required}></span>
          {showCheckbox ? (
            <Checkbox
              key={`checkbox-${getInstanceId()}`}
              inputId={`not_applicable_${title.toLowerCase()}`}
              defaultChecked={disabledInput}
              name={`${title}_applicable`}
              value={title}
              onChange={(_checked, _e) => {
                setDisabledInput((prev) => !prev);
              }}
            >
            Not applicable
          </Checkbox>
          ) : null}
        </h6>) : null}
      {content ? <p data-group-subtitle dangerouslySetInnerHTML={{ __html: content }}></p> : null}
      {children}
    </div>
  );

};

export default GroupHeader;
