import { Children, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { uuid } from '@grrr/utils';
import { getRelativeDateTime } from 'helpers/dateTime';

import { BackLink, Button, ButtonSet, TeamMember, IconComponent, Loader, Tabs, TopContent, SearchField, Filters, Aside, Icon, SearchFilter, Accordion, AccordionItem, LegislationListCard, EmptyLegislationList } from 'components';

import { useQueryApi } from 'hooks/index';
import { useLegislationContext, useNavigatorContext, useUserContext } from 'contexts';
import { GET_CLIENT_PROJECT_DETAILS, GET_ROLE_CONTENT } from 'configs/api-endpoints';
import { PROJECT_DETAILS_TABLIST } from 'configs/project/project';
import { Legislation, UserProfile } from 'hooks/interfaces';
import { Filter } from 'hooks/interfaces/legislation.interface';
import { NavigatorLegislation } from 'hooks/interfaces/navigator.interface';
import { NAVIGATOR_FILTER_PAYLOAD_ID } from 'configs/legislation/legislation';
import { isMatchedSearched, store } from 'helpers/utils';
import { composeLegislationNavigatorPayload } from 'helpers/legislations/legislation';
import FilterItem from 'pages/Navigator/FilterItem';
import styles from './ProjectDetails.module.scss';
import { sortNavigatorLegislationData } from 'hooks/reducers/helpers';

const TAB_TITLE: { [key: number]: string } = {
  '1': 'Provide the general details about the client.',
  '2': 'PwC admins get access to the project environment. Admins can modify and publish content to the environment based on their permission rights.',
  '3': 'View the legislation pieces that match the attributes based on the client\'s needs and objectives.',
  '4': 'Preview the client environment from the client\'s perspective.'
}


const ProjectDetails = () => {

  const tabRef = useRef<HTMLUListElement | null>(null);

  const { projectId } = useParams();
  const id = useMemo(() => uuid(), []);

  const { user } = useUserContext();
  const { state } = useLegislationContext();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const projectDetailsTablist = useMemo(() =>
    user.is_admin
    ? [...PROJECT_DETAILS_TABLIST, { identifier: 'preview_client_view', label: 'Preview client view', description: 'Preview client view'}]
    : PROJECT_DETAILS_TABLIST,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { get: getProjectDetails } = useQueryApi({
    ...GET_CLIENT_PROJECT_DETAILS,
    endpoint: `${GET_CLIENT_PROJECT_DETAILS.endpoint}${projectId}`
  })
  const { data: projectData, error: projectError, isLoading: isPLoading } = getProjectDetails(null);



  /**
   * Handle tab click.
   */
  const tabClickHandler = (index: number) => {
    setSelectedIndex(Number(index));
  };

  const alertContent = user.is_admin
    ? 'Please be aware that this is still under development and may be missing some features/functionalities.'
    : `Please be aware that this is still under development and may be missing some features/functionalities.
      \n [- Admins will be able to see more and do more at this page -]`;

  return (
    <Fragment>
      <TopContent isDetails data-details-page showAlert content={alertContent}>
        <BackLink>Back</BackLink>
        <div className={styles.root__topcontent}>
          <h3>{projectData?.name}</h3>
          <span data-is-published={projectData?.is_published} data-is-draft={projectData?.is_draft}>
            <small className={styles.root__pulser}>&nbsp;</small>
            {projectData?.is_published ? 'Published' : projectData?.is_draft ? 'Draft' : 'Created'}
          </span>
        </div>
        <p>View and edit the client environment.</p>

        {/* Tabs */}
        <Tabs
          tabSelected={selectedIndex}
          options={projectDetailsTablist.map((tab) => tab.label)}
          onTabClick={tabClickHandler}
          data-tabs
          align="left"
          type="tabs"
          showLastTabIcon={user.is_admin}
          ref={tabRef}
        >
          {user.is_admin ? (
            <ButtonSet data-btn-set>
              <Button
                variation='primary'
                size="small"
                url={`/projects/${projectId}/edit`}
                title={`Publish: ${(projectData)?.name || ''}`}
                onClick={() => {}}
                data-edit-btn
              >
                Edit Project
              </Button>
            </ButtonSet>
          ) : null}
        </Tabs>
      </TopContent>

      <article className={styles.root__article} data-content>
        <p>{TAB_TITLE[selectedIndex]}</p>
        {projectData ? (
          <Fragment>
            <ClientEditDetails selectedIndex={selectedIndex} project={projectData}/>
            <AdminSetupEditDetails selectedIndex={selectedIndex} project={projectData}/>
            <ContentConfigurationEditDetails selectedIndex={selectedIndex} project={projectData}/>
            <PreviewClientView selectedIndex={selectedIndex} project={projectData} filters={state.filters}/>
          </Fragment>
        ) : (
          <Loader data-medium />
        )}
      </article>
    </Fragment>
  );

};

export default ProjectDetails;

interface Client {
  identifier: string;
  client_external_member: [];
  client_team_member: [];
  email: string;
  first_name: string;
  groups: string[];
  initials: string;
  is_onboarded: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  job_roles: { identifier: string; name: string; }[];
  last_name: string;
  user_permissions: string[];
  username: string;
}
interface Project {
  associated_legislations: Legislation[];
  client_members: Client[];
  created_at: string;
  description: string;
  domain: string;
  identifier: string;
  is_published: boolean;
  name: string;
  team_members: UserProfile[];
  starting_date: string;
  updated_at: string;
}
interface EditComponentProps {
  selectedIndex: number,
  project: Project;
  direction?: string
  filters?: Filter[];
}
interface SelectedAttributes {
  [key: string]: { label: string, data: any[] },
  type: { label: string, data: any[] },
  topic: { label: string, data: any[] },
  issuing_jurisdiction: { label: string, data: any[] },
  geographical_scope: { label: string, data: any[] },
  job_roles: { label: string, data: any[] },
  product_service: { label: string, data: any[] },
}
const emptyAttributes: SelectedAttributes = {
  type: { label: 'Type', data: [] },
  topic: { label: 'Topic', data: [] },
  issuing_jurisdiction: { label: 'Issuing Jurisdiction', data: [] },
  geographical_scope: { label: 'Geographical Scope', data: [] },
  job_roles: { label: 'Job Roles', data: [] },
  product_service: { label: 'Product/Service', data: [] },
}
// Function to remove duplicates based on 'identifier'
const removeDuplicates = (array: any = []) => {
  const seen = new Set();
  return array.filter((item: any) => {
      const duplicate = seen.has(item?.identifier);
      seen.add(item?.identifier);
      return !duplicate;
  });
}

const ClientEditDetails = ({ selectedIndex, project }: EditComponentProps) => {
  return (
    <section className={styles.root__client_details} data-hidden={!(selectedIndex === 1)} data-create-content>

      {/* Log of the client */}
      <article data-logo>
        <span>Company logo</span>
        <div data-client-logo>
          <IconComponent name="ClientLogoIcon" />
        </div>
      </article>

      {/* Client details */}
      <section data-edit-group="client_details">
        <div data-row>
          <span data-title>project name</span>
          <span data-subtitle>{project.name}</span>
        </div>

        <div data-row>
          <span data-title>project description</span>
          <span data-subtitle>{project.description}</span>
        </div>

        <div data-row>
          <span data-title>user email domain</span>
          <span data-subtitle>{project.domain}</span>
          <span data-domain>
            How will this domain be used? Based on the input provided regarding the clients domain, all the users that log in to the platform using that domain, will have access to this client environment. eg. if the domain is @pwc.com, all the pwc employees that log in using the PwC SSO will be redirected to the PwC legislation environment
          </span>
        </div>

        <div data-row>
          <span data-title>starting date of engagement</span>
          <span data-subtitle>{project.starting_date || 'Currently unknown'}</span>
        </div>
      </section>
    </section>
  );

};

const AdminSetupEditDetails = ({ selectedIndex, project }: EditComponentProps) => {
  /**
   * Group members based on their roles.
   */
  const groupedMembers = useMemo(() => {
    const uniqueRoles = Array.from(new Set(['approver', 'preparer']));
    return uniqueRoles.map((role: string | undefined) => {
      return {
        role,
        subtitle: role === 'approver'
          ? 'Master admins can do any content modifications within a client\'s environment and also publish the changes to the client\'s view.'
          : 'General admins can modify the content within a client\'s environment. ',
        members: project.client_members?.filter((member: Client) => member.groups[0] === role)
      }
    });
  }
  , [project.client_members]);

  return (
    <section className={styles.root__admin_setup} data-hidden={!(selectedIndex === 2)} data-create-content>
      <div data-row>
        <span data-title>invited admins<small>{project.client_members?.length || 0}</small></span>
      </div>
      <article data-div-group>
        {Children.toArray(groupedMembers.map((group: any) => (
          <div className={styles.root__team} data-empty={project.client_members.length === 0}>
            <h6>{group.role || 'preparer'} Admins</h6>
            <span data-subtitle>{group.subtitle}</span>
            {group.role !== 'approver' ? (<br/>) : null}
            <span>&nbsp;</span>
            <ol
              hidden={false}
              aria-live="polite"
              aria-label="Admin Users"
            >
              {Children.toArray(group.members?.map((member: Client, index: number) => (
                <li
                  className={styles.root__person}
                  data-index={index}
                  tabIndex={0}
                >
                  <TeamMember
                    data={member as unknown as UserProfile}
                    query={member.email}
                    disabled={false}
                    type="details"
                    onRemove={() => {}}
                  />
                </li>
              )))}
            </ol>
          </div>
        )))}
      </article>
    </section>
  );

};

const ContentConfigurationEditDetails = ({ selectedIndex, project }: EditComponentProps) => {

  // Extract all unique attributes from the associated legislations
  const allAttributes: SelectedAttributes = useMemo(() => {
    if (!project.associated_legislations) return emptyAttributes;

    const extractAndRemoveDuplicates = (key: string) => {
      return removeDuplicates(
        project
          .associated_legislations
          .flatMap((legislation) => (legislation as Legislation)[key as keyof Legislation] as any),
      );
    };

    return {
      type: { label: 'Legislation Type', data: extractAndRemoveDuplicates('type') },
      topic: { label: 'Sustainability Topic', data: extractAndRemoveDuplicates('topic') },
      issuing_jurisdiction: { label: 'Issuing Jurisdiction', data: extractAndRemoveDuplicates('issuing_jurisdiction') },
      geographical_scope: { label: 'Geographical Scope', data: extractAndRemoveDuplicates('geographical_scope') },
      job_roles: { label: 'Job Roles', data: extractAndRemoveDuplicates('job_roles') },
      product_service: { label: 'Product or Service', data: extractAndRemoveDuplicates('product_service') },
    };
  }, [project.associated_legislations]);


  const getRelativeDate = (legislation: Legislation) => {
    const { value, label } = getRelativeDateTime(legislation.created_at || '', { includeTime: true });
    return `${value} ${label} ago`;
  };


  return (
    <section className={styles.root__content_configuration} data-hidden={!(selectedIndex === 3)} data-create-content>
      <aside>
        <h6>Selected attributes</h6>
        {Children.toArray(Object.keys(allAttributes).map((key: string) => (
          <ul>
            <span>{allAttributes[key].label}</span>
            {Children.toArray(allAttributes[key].data?.map((attribute: any) => (
              <li>
                <span>{attribute?.name}</span>
              </li>
            )))}
          </ul>
        )))}
      </aside>

      <article data-legislation-content>
        <header>
          <h6>Configured client legislation list | <span>{project.associated_legislations?.length || 0} results</span></h6>
          <SearchField
            id={`search-filter-legislation-details-${project.identifier}`}
            label="Search legislation"
            name="search_legislation"
            onSearch={() => {}}
            scrollOnFocus
          />
        </header>
        <div data-legislation-list>
          <ul>
            {Children.toArray(project.associated_legislations.map((legislation: Legislation) => (
              <li data-legislation-card>
                <NavLink
                  to={`#!`}
                  title={`See client role-specific content for: ${legislation.name_local}`}
                  aria-label={`See client role-specific content for: ${legislation.name_local}`}
                  >
                    <div>
                      <span>{legislation.name_generic  === 'nan' ? legislation.name_local : legislation.name_generic }</span>
                      <span>Checked {getRelativeDate(legislation)}</span>
                    </div>
                    <ButtonSet>
                      <Button
                        variation='transparent'
                        size="small"
                        title={`See client role-specific content for: ${legislation.name_generic}`}
                        onClick={() => {}}
                        data-edit-btn
                      >
                        See client role-specific content
                        <IconComponent name="RightChevron" />
                      </Button>
                    </ButtonSet>
                  </NavLink>
              </li>
            )))}
          </ul>
        </div>
      </article>
    </section>
  );

};

const PreviewClientView = ({ selectedIndex, project }: EditComponentProps) => {

  const clientProjectAccordionId = useMemo(() => uuid(), []);


  const { user } = useUserContext();
  const { state, stateDispatch: dispatch, stateActions } = useNavigatorContext();
  const isActiveFiltersEmpty = state.filters.map((f) => f.data.every((o) => !o.is_approved)).every((c) => c);


  const { post: getRoleContent } = useQueryApi(GET_ROLE_CONTENT);
  const { data: roleContentData, mutate: mutateRoleContent, isSuccess, isPending } = getRoleContent();

  // State
  const [LegislationRoleContent, setLegislationRoleContent] = useState<any[]>([])
  const [activeKeys, setActiveKeys] = useState<string[]>();

  /**
   * Handles accordion click event
   */
  const onClickAccordion = (activeKeys: string[]) => {
    setActiveKeys(activeKeys);
  };

  /**
   * Handles the filter change
   */
  const onFilterChangeHandler = (checked: boolean, filterOption: Filter, name:string) => {
    dispatch(stateActions.setActiveFilters(checked, filterOption, name ?? ''));
  };

  /**
   * Filter the filters for only the categories that has any filter(s) already approved/checked
   */
  const activeFilters = useMemo(() => {
    const filters: Filter[] = state.filters;
    return filters
      ?.filter((_f) => _f.data.some((d) => d.is_approved ? true : false))
      ?.map((f) => ({
        ...f,
        data: f.data.filter((_d) => _d.is_approved)
      }))
  }, [state.filters]);


  /**
   * Handles the navigation to the legislation page
   */
  const onNavigateLegislationHandler = () => {
    const payload = composeLegislationNavigatorPayload(
      state.filters,
      [] // Empty because the ADMIN is looking at this now
    );
    store(NAVIGATOR_FILTER_PAYLOAD_ID, payload, { permanent: false });

    if (!payload.job_role_list.length && !Object.keys(payload.selectors).length) {
      return;
    }
    mutateRoleContent(payload);
  };


  /**
   * Set the navigator legislation data
   */
  useEffect(() => {
    if (!roleContentData?.errors?.length && isSuccess) {
      const { results } = roleContentData;
      const  filtered = results.filter((nav: NavigatorLegislation) => isMatchedSearched(nav.legislation, state.query));
      const filteredNavLegislations = sortNavigatorLegislationData(filtered, state.sortOrder);
      dispatch(stateActions.initState({ navLegislations: results || [], filteredNavLegislations }));
      setLegislationRoleContent(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);


  /**
   * Handle close/remove clicks.
   */
  const removeHandler = (id: string) => {
    const filterOption = state.filters.find((f) => {
      return f.data.some((d) => d.identifier === id)
    });

    if (filterOption) {
      const name = filterOption.data.find((d) => d.identifier === id)?.name;
      dispatch(stateActions.setActiveFilters(false, filterOption, name));
      return;
    }
    console.warn('could not find the filter option to be updated........')
  };

  /**
   * Handle search input updates and fetch new queries.
   */
  const onSearch = (e: any) => {
    const query = e.target.value;
    dispatch(stateActions.setQuery(query || ''));
  };

  /**
   * Handle sort change.
   */
  const onSortChangeHandler = (e: any) => {
    const target = e.target.closest('button') as HTMLElement;
    const sortValue = target.dataset.sortValue || 'asc';
    dispatch(stateActions.setSortOrder(sortValue));
    dispatch(stateActions.sortNavLegislations(sortValue));
  };


  /**
   * Dropdown Filter handler
   */
  const onDataFilterHandler = (filterValue: string) => {
    dispatch(stateActions.setFilterBy({ filterValue }));
  };

  return (
    <section className={styles.root__preview_client_view} data-hidden={!(selectedIndex === 4)} data-create-content>
      <Aside user={user}>
        <header data-aside-header>
          <h4 className={styles.root__header__title}>Selector</h4>
          <span>Select your fields of interest</span>
        </header>

        <Filters
          onSideFilterChange={onFilterChangeHandler}
          filters={state.filters}
          // This is empty bcos the admin does not need it -- it just a preview of what the client will see
          userRoles={[]}
          data-nav-landing
          data-accordion
        >
          <ButtonSet data-aside-button>
            <Button
              type='button'
              variant="primary"
              onClick={onNavigateLegislationHandler}
              disabled={isActiveFiltersEmpty}
            >
              Navigate legislation
            </Button>
          </ButtonSet>
        </Filters>
      </Aside>

      {/* Main Content on the Left */}
      {!isPending && !isSuccess && !LegislationRoleContent.length ? (
        <article data-legislation-content data-empty-state>
          <IconComponent name="EmptyNavigator" data-empty-icon />
          <div className={styles.root__searchempty}>
            <IconComponent name="SearchEmptyState" />
          </div>
          <h6 className={styles.root__searchemptytext}>
            Select your preferred filters to navigate legislations relevant to you and your defined role(s)
          </h6>
        </article>
      ) : (
        <article data-legislation-content>
           <header>
            <span>Refine the filtering further, may give you more results: </span>
            <div>
              <Icon name="exclamation-triangle" />
              <span><strong>Disclaimer:</strong>&nbsp;Users advised to verify and cross-reference information.</span>
            </div>
          </header>
          <ul className={styles.root__filters} data-show-guide={user.show_filters_tour}>
            {activeFilters.length ? Children.toArray(activeFilters.map((f: Filter, index: number) => (
              Children.toArray(f?.data.map((o) => (
                <FilterItem
                  hidden={o.is_approved || false}
                  option={o}
                  onItemRemove={removeHandler}
                />
              ))
            )))) : null}
          </ul>
          {/* Search Filter  */}
          <SearchFilter
            onSearch={onSearch}
            onDataSort={onSortChangeHandler}
            onFilterOptionSelect={onDataFilterHandler}
            id={clientProjectAccordionId}
          />

          {state.navLegislations.length ? (
            <Accordion
              multiple={false}
              onClick={onClickAccordion}
              activeKeys={activeKeys}
              accordionId={clientProjectAccordionId}
              data-show-guide={false}
            >
              {state.navLegislations.length ? Children.toArray(state.navLegislations.map((navLegislation, index: number) => (
                <AccordionItem
                  contentTitle={navLegislation.legislation.name_local || 'No name available'}
                  description={`Type: ${navLegislation?.legislation?.scope}` || 'No scope available'}
                  itemKey={navLegislation.legislation.identifier}
                  query={state.query}
                  isLarge
                >
                  <LegislationListCard
                    key={navLegislation.legislation.identifier}
                    legislation={navLegislation as NavigatorLegislation}
                    user={user}
                    query={state.query}
                    seCurrentLegislation={
                      () => dispatch(stateActions.seCurrentLegislation(navLegislation.legislation.identifier))
                    }
                    isListCard={false}
                  />
                </AccordionItem>
              ))) : null}
            </Accordion>
          ) : null }
          {/* Empty message */}
          <EmptyLegislationList showContent={!state.navLegislations.length} query={state.query}></EmptyLegislationList>
        </article>
      )}
    </section>
  );

};
