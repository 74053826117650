import { IconComponent, Button } from 'components';
import { TeamMemberProps } from './TeamMember.interface';

import styles from './TeamMember.module.scss';

const TeamMember = ({
  data,
  query = '',
  disabled,
  onRemove,
  type = 'create',
  ...props
}: TeamMemberProps) => {
  return (
    <article className={styles.root} data-type={type}>
      <div className={styles.root__info}>
        <p>{data.email}</p>
        {data.groups.length ? (
          <span>{data.groups[0]}</span>
        ) : <span>&nbsp;-</span>}
      </div>
      {type === 'details' ? null : (
        <Button
          variation="transparent"
          onClick={() => onRemove(data)}
        >
          <IconComponent name="DeleteFillIcon" />
        </Button>
      )}
    </article>
  );

};

export default TeamMember;
