import { Children, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { getFilters, getIdentifiers, highlight, updateData } from 'helpers/utils';
import { getRelativeDateTime } from "helpers/dateTime";

import { Button, ButtonSet, EmptyLegislationList, FormGroup, IconComponent, SearchField } from "components";
import { GroupHeader, SidebarSelect, TopicList } from "pages/Legislation/Admin/EditContent/TabPages";

import { Legislation } from "hooks/interfaces";
import { EditComponentProps, SelectedAttributes } from "../interfaces";
import { GENERAL_FILTER_TABS } from "configs/legislation/legislation";
import styles from './ContentConfig.module.scss';

const emptyAttributes: SelectedAttributes = {
  type: { label: 'Type', data: [] },
  topic: { label: 'Topic', data: [] },
  issuing_jurisdiction: { label: 'Issuing Jurisdiction', data: [] },
  geographical_scope: { label: 'Geographical Scope', data: [] },
  job_roles: { label: 'Job Roles', data: [] },
  product_service: { label: 'Product/Service', data: [] },
}

const ContentConfigurationEditTab = ({
  project,
  selectedIndex,
  setCurrentEditCard,
  setIsEditing,
  onCheckboxChange,
  dispatch,
  actions,
  filters = [],
  legislations = [],
  selectedFilters,
}: EditComponentProps & {
  setCurrentEditCard: (legislation: Legislation) => void;
  setIsEditing: (isEditing: boolean) => void;
  onCheckboxChange: (e: any, isChecked: boolean) => void;
  dispatch: any;
  actions: any;
  legislations: Legislation[];
  allAttributes?: SelectedAttributes;
  selectedFilters?: any;
}) => {

  const [productService, setProductService] = useState(filters.find((f) => f.label === 'product_service')?.data || []);
  const [geographicalScope, setGeographicalScope] = useState(filters.find((f) => f.label === 'geographical_scope')?.data || []);
  const [sustainabilityTopic, setSustainabilityTopic] = useState(filters.find((f) => f.label === 'topic')?.data || []);
  const [legislationTypes, setLegislationTypes] = useState(filters.find((f) => f.label === 'type')?.data || []);
  const [status, setStatus] = useState(filters.find((f) => f.label === 'status')?.data || []);
  const [jobRoles, setJobRoles] = useState(filters.find((f) => f.label === 'job_roles')?.data || []);

  const [legislationList, setLegislationList] = useState<Legislation[]>(project.associated_legislations);
  const [attributesCleared, setAttributesCleared] = useState<boolean>(false);
  const [allSelectedAttributes, setAllSelectedAttributes] = useState<SelectedAttributes>(emptyAttributes);
  const [query, setQuery] = useState<string>('');



  /**
   * Function to remove duplicates based on 'identifier'
   * @param array
   * @returns
   */
  const removeDuplicates = (array: any) => {
    if (!array) return [];

    const seen = new Set();
    return array.filter((item: any) => {
      const duplicate = seen.has(item?.identifier);
      seen.add(item?.identifier);
      return !duplicate;
    });
  }

  /**
   * Extract all unique attributes from the associated legislations
   * @returns
   **/
  useMemo(() => {
    if (!legislationList) return emptyAttributes;

    const extractAndRemoveDuplicates = (key: string) => {
      if (!key) return [];

      return removeDuplicates(
        attributesCleared ? [] : legislationList
          .flatMap((legislation) => (legislation as Legislation)[key as keyof Legislation] as any),
      );
    };

    const data = {
      type: { label: 'Legislation Type', data: extractAndRemoveDuplicates('type') },
      topic: { label: 'Sustainability Topic', data: extractAndRemoveDuplicates('topic') },
      issuing_jurisdiction: { label: 'Issuing Jurisdiction', data: extractAndRemoveDuplicates('issuing_jurisdiction') },
      geographical_scope: { label: 'Geographical Scope', data: extractAndRemoveDuplicates('geographical_scope') },
      job_roles: { label: 'Job Roles', data: extractAndRemoveDuplicates('job_roles') },
      product_service: { label: 'Product or Service', data: extractAndRemoveDuplicates('product_service') },
    };

    setAllSelectedAttributes(data);
  }, [legislationList, attributesCleared]);


  /**
   * Get the topic roles and legislation topics.
   */
  useMemo(() => {
    setSustainabilityTopic(updateData(getFilters(filters, 'topic'), getIdentifiers(allSelectedAttributes.topic.data)));
    setLegislationTypes(updateData(getFilters(filters, 'type'), getIdentifiers(allSelectedAttributes.type.data)));
    setGeographicalScope(updateData(getFilters(filters, 'geographical_scope'), getIdentifiers(allSelectedAttributes.geographical_scope.data)));
    setProductService(updateData(getFilters(filters, 'product_service'), getIdentifiers(allSelectedAttributes.product_service.data)));
    setStatus(updateData(getFilters(filters, 'status'), getIdentifiers([])));
    setJobRoles(updateData(getFilters(filters, 'job_roles'), getIdentifiers(allSelectedAttributes.job_roles.data)));
  }, [filters, allSelectedAttributes]);


  /**
   * Clear all selected attributes
   */
  const onClearAllAttributesHandler = () => {
    setSustainabilityTopic(updateData(getFilters(filters, 'topic'), []));
    setLegislationTypes(updateData(getFilters(filters, 'type'), []));
    setGeographicalScope(updateData(getFilters(filters, 'geographical_scope'), []));
    setProductService(updateData(getFilters(filters, 'product_service'), []));
    setStatus(updateData(getFilters(filters, 'status'), []));
    setJobRoles(updateData(getFilters(filters, 'job_roles'), []));
    setLegislationList([]);
    setAllSelectedAttributes(emptyAttributes);
    setAttributesCleared(true);
  };


  /**
   * Get the human readable relative date
   * @param legislation
   * @returns
   */
  const getRelativeDate = (legislation: Legislation) => {
    const { value, label } = getRelativeDateTime(legislation.created_at || '', { includeTime: true });
    return `${value} ${label} ago`;
  };


  /**
   * Handle search input updates and fetch new queries.
  */
  const legislationSearchChangeHandler = (e: any) => {
    const query = e.target.value;
    setQuery(query);
    attributesCleared && !query.trim() ? null : dispatch(actions.setQuery(query || ''));
  };


  /**
   * Handle resetting the list of legislations when the query is empty.
   */
  useEffect(() => {
    // setLegislationList(legislations);
  }, [legislations]);


  return (
    <section className={styles.root} data-hidden={!(selectedIndex === 3)} data-create-content data-content-configuration>
      <article data-selected-attributes>
        <header>
          <h6>Selected client attributes</h6>
          <Button
            variation="transparent"
            type="button"
            size="small"
            title="Clear all selected filters for this Client"
            onClick={onClearAllAttributesHandler}
          >Clear all selected attributes</Button>
        </header>
        <FormGroup data-edit-group="sustainability_topic">
          <GroupHeader title="sustainability topic" content="Choose the sustainability topic(s) that the project focuses on:"/>
          <TopicList data={sustainabilityTopic} name="topic" />
        </FormGroup>

        <FormGroup data-edit-group="issuing_jurisdiction">
          <GroupHeader title="'issuing' jurisdiction" content="Select the country or jurisdiction that issued the legislation"/>
          <SidebarSelect
            name="issuing_jurisdiction_country"
            sidebarItems={GENERAL_FILTER_TABS}
            selectedNav=''
            selectedJurisdictions={[]}
            onCheckboxChange={onCheckboxChange}
            data-jurisdiction
            />
        </FormGroup>

        <FormGroup data-edit-group="legislation_type">
          <GroupHeader title="legislation type" content="Select the current status of the legislation."/>
          <TopicList data={legislationTypes} name="type"/>
        </FormGroup>

        <FormGroup data-edit-group="relevant_product_group_service">
          <GroupHeader title="relevant product (group) or services" content="If applicable, specify whether the legislation applies to specific products and/ or services."/>
          <TopicList data={productService} name="product_service"/>
        </FormGroup>

        <FormGroup data-edit-group="geographical_scope">
          <GroupHeader title="geographical scope" content="If applicable, specify whether the legislation applies to specific products and/ or services."/>
          <TopicList data={geographicalScope} name="product_service"/>
        </FormGroup>
      </article>

      <article data-legislation-content>
        <header>
          <h6>Configured client legislation list | <span>{legislationList?.length || 0} results</span></h6>
            <SearchField
              id={`search-filter-legislation-details-${project.identifier}`}
              label="Search legislation"
              name="search_legislation"
              onSearch={legislationSearchChangeHandler}
              scrollOnFocus
            />
        </header>
        <div data-legislation-list>
          <ul>
            {Children.toArray(legislationList.map((legislation: Legislation) => (
              <li data-legislation-card>
                <NavLink
                  to={`#!`}
                  title={`See client role-specific content for: ${legislation.name_local}`}
                  aria-label={`See client role-specific content for: ${legislation.name_local}`}
                  onClick={() => {
                    setIsEditing(true);
                    setCurrentEditCard(legislation)
                  }}
                  >
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: highlight(legislation.name_generic  === 'nan' ? legislation.name_local : legislation.name_generic, query)
                        }}
                      ></span>
                      <span>Checked {getRelativeDate(legislation)}</span>
                    </div>
                    <ButtonSet>
                      <Button
                        variation='transparent'
                        size="small"
                        title={`See client role-specific content for: ${legislation.name_generic}`}
                        onClick={() => {
                          setIsEditing(true);
                          setCurrentEditCard(legislation)
                        }}
                        data-edit-btn
                      >
                        See client role-specific content
                        <IconComponent name="RightChevron" />
                      </Button>
                    </ButtonSet>
                  </NavLink>
              </li>
            )))}
          </ul>
          <EmptyLegislationList query="" showContent={legislationList.length <= 0} data-empty/>
        </div>
      </article>
    </section>
  );

};

export default ContentConfigurationEditTab;

