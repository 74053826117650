import { useContext, createContext } from "react";

import { useNavigator } from 'hooks';
import { NavigatorState } from "hooks/interfaces/navigator.interface";
import { ContextProps } from "hooks/interfaces";

const NavigatorContext = createContext<NavigatorState>({} as NavigatorState);

const NavigatorContextProvider = ({ children }: ContextProps) => {
  const navigator = useNavigator();

  const updatedNavigator = {
    ...navigator,
    stateDispatch: navigator.dispatch,
    stateActions: navigator.actions,
    refetch: navigator.refetchLegislations,
  };

  return (
    <NavigatorContext.Provider value={updatedNavigator}>
      {children}
    </NavigatorContext.Provider>
  );
};

const useNavigatorContext = () => useContext(NavigatorContext);

export { NavigatorContextProvider };
export default useNavigatorContext;
