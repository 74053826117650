import { Fragment, useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react';
import { setupGetInstanceId } from 'helpers/utils';
import { Checkbox } from 'components';
import { FormEditInputProps } from './FormEditInput.interface';

import styles from './Input.module.scss';

const DISABLE_KEYS = ['nan', 'N/A', 'n/a', 'na', 'NA', 'not_applicable', 'not applicable'];

const getInstanceId = setupGetInstanceId();

const FormEditInput = ({
  type,
  name = '',
  label = '',
  subLabel = '',
  defaultValue = '',
  placeholder = '',
  rows = 3,
  disabled = false,
  required = false,
  showCheckbox = false,
  isTextArea = false,
  maxLength = 200,
  minLength = 50,
  onNotApplicable,
  children,
  ...props
}: FormEditInputProps) => {
  const TextAreaRef = useRef<HTMLTextAreaElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>(defaultValue || '');
  const [textAreaText, setTextAreaText] = useState(DISABLE_KEYS.includes(defaultValue) ? 'N/A' : defaultValue || '');
  const [disabledInput, setDisabledInput] = useState<boolean>(DISABLE_KEYS.includes(defaultValue)  || disabled);


  /**
   * Set the default value when defaultValue changes
   */
  useEffect(() => {
    setInputValue(defaultValue || '');
    setTextAreaText(defaultValue || '');
  }, [defaultValue]);


  /**
   * When disabledInput changes, update inputValue based on its value
   */
  useEffect(() => {
    if (disabledInput) {
      setInputValue('N/A');
      setTextAreaText('N/A');
    } else {
      setInputValue(defaultValue);
      setTextAreaText(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledInput, defaultValue, name]);


  /**
   * A function to count words to a max value of 500
   * @param text
   * @returns
   */
  const countWords = (text: string = '') => {
    let textValue: string[] = [];

    if (Array.isArray(text)) {
      textValue = `${text[0] || ''}`?.trim()?.split(/\s+/)?.filter((s) => s.length > 0) || [];
      return textValue.length || 0;
    }
      // Split the value into words, filter out empty strings
    textValue = `${text}`?.trim()?.split(/\s+/)?.filter((s) => s.length > 0) || [];
    return textValue.length || 0;
  };

  /**
   * Handle the onChange event for the textArea
   * @param event
   * @returns
   */
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!event?.target) return;

    const text = event.target.value;
    const words = text.trim().split(/\s+/);
    if (words.length <= 500) {
      setTextAreaText(text);
    } else {
      // If the word count exceeds the limit, truncate and rejoin with spaces and preserved new lines
      let currentWordCount = 0;
      const truncatedText = words.reduce((acc, word) => {
      if (currentWordCount < 500 || !word.trim().length) {
        acc += word + ' ';
        // Increment word count only for non-whitespace characters
        if (word.trim()) {
        currentWordCount++;
        }
      }
      return acc;
      },'');
      setTextAreaText(truncatedText);
      event.target.value = truncatedText;
    }
  };


  /**
   * Calculate the words remaining
   */
  const wordsRemaining = 500 - countWords(textAreaText);
  const showWordsLeft = wordsRemaining === 500
      || (textAreaText && textAreaText[Number(textAreaText?.length) - 1] === '\n')
      || (textAreaText && textAreaText[Number(textAreaText?.length) - 1]?.trim() === '.');


  /**
   * Check if the lines are equal to rows and add one more line/row
   */
  const checkLines = useCallback((e: any) => {
    const lines = e.target.value.split('\n').length;
    if (lines >= rows) {
      e.target.rows = lines + 1;
    }
  }, [rows]);


  /**
   * Check the lines when the textAreaText changes
   */
  useLayoutEffect(() => {
    const currentTextAreaRef = TextAreaRef.current;
    if (currentTextAreaRef) {
      currentTextAreaRef.addEventListener('input', checkLines);
    }
    return () => {
      if (currentTextAreaRef) {
        currentTextAreaRef.removeEventListener('input', checkLines);
      }
    };
  }, [checkLines, textAreaText]);


  /**
   * Set the textAreaText value to N/A when disabledInput changes
   */
  useEffect(() => {
    if (!TextAreaRef?.current) return;
    disabledInput ? TextAreaRef.current.value = 'N/A' : TextAreaRef.current.value = textAreaText;
  }, [disabledInput, textAreaText]);


  return (
    <div className={styles.root__forminput} {...props} data-disabled={disabledInput}>
      {label ? (
        <label data-required={required} htmlFor={name}>
          <span data-disabled={disabledInput}>{label}</span>
          {showCheckbox ? (
            <Checkbox
              key={`checkbox-${getInstanceId()}`}
              inputId={`not_applicable_${name.toLowerCase()}`}
              defaultChecked={disabledInput}
              name={`${name}`}
              value={'not_applicable'}
              onChange={(_checked, _e) => {
                setDisabledInput((prev) => !prev);
                onNotApplicable && onNotApplicable((prev: { [key: string]: any }) => ({
                  ...prev,
                  [name]: _checked ? 'N/A' : defaultValue,
                }));
              }}
              data-not-applicable={disabledInput}
            >
              Not applicable
            </Checkbox>
            ) : null}
        </label>
      ) : null}
      {subLabel && <span data-subtitle dangerouslySetInnerHTML={{ __html: subLabel }}></span>}
      {!isTextArea ? (
        <input
          id={name}
          type={type}
          name={name}
          key={inputValue}
          required={required}
          defaultValue={disabledInput ? 'N/A' : inputValue}
          data-value={inputValue}
          placeholder={placeholder}
          onChange={(e) => {
            onNotApplicable && onNotApplicable((prev: { [key: string]: any }) => ({
              ...prev,
              [name]: disabledInput ? 'N/A' : e.target.value,
            }));
          }}
          disabled={disabled || disabledInput}
          ref={inputRef}
        />) : (
        <Fragment>
          <textarea
            id={name}
            name={name}
            rows={rows}
            ref={TextAreaRef}
            required={required}
            placeholder={placeholder}
            onChange={handleOnChange}
            data-value={textAreaText}
            defaultValue={(disabledInput || disabled) ? 'N/A' : textAreaText}
            disabled={disabled || disabledInput}
          />
          <span className={styles.root__charactersleft} data-hidden={showWordsLeft}>
            <strong>{wordsRemaining === 500 ? 500 : wordsRemaining}&nbsp;</strong> word{wordsRemaining > 1 ? 's' : ''} remaining
          </span>
        </Fragment>
      )}
      {children}
    </div>
  );

};

export default FormEditInput
