import { useState, Fragment, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { nl2br } from 'helpers/utils';

import fData from 'data/footer.json';

import { Button, IconComponent, Modal } from 'components';
import { FooterData, FooterProps } from './Footer.interface';
import styles from './Footer.module.scss';

const FOOTER_LINKS = ['privacy', 'cookie_policy', 'disclaimer', 'site_provider'];
const FOOTER_DATA_ENDPOINT = 'https://footertool.pwc.nl/json/?lang=en';


const Footer = ({ children, ...props }: FooterProps) => {
  const location = useLocation();

  const [footerData, setFooterData] = useState<FooterData | null>(null);
  const [policyData, setPolicyData] = useState<any>();
  const [open, setOpen] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['navigator/footer/'],
    queryFn: () => Promise.resolve(fData),
    // queryFn: () => fetch(FOOTER_DATA_ENDPOINT, { method: 'GET' })
    //   .then((res) => res.json()).then((data) => data),
    staleTime: 5 * 60 * 60 * 1000,
    retry: 3,
    retryDelay: 5 * 60 * 1000,
  });

  /**
   * Set the footer data
   */
  useEffect(() => {
    if (!(data === undefined)) {
      setFooterData({ ...data, domain: [] });
    }

    if (isError) {
      console.warn('Failed fetching the footer data...... :-) ')
    }
  }, [isLoading, data, isError]);

  return (
    <Fragment>
      <footer
        className={styles.root}
        {...props}
      >
        {children}
        {footerData?.copyright ? (
        <p dangerouslySetInnerHTML={{ __html: footerData.copyright.body.replace('<br/>', '') || '' }}></p>
        ) : null}
        <ul className={styles.root__links}>
          {FOOTER_LINKS.map((item: string) => (
              <li key={item}>
                <Link
                  to={`${location.pathname}`}
                  onClick={() => {
                    setOpen(true);
                    setPolicyData(footerData?.[item as keyof FooterData] || {});
                  }}
                >{(footerData?.[item as keyof FooterData] as any)?.title || ''}</Link>
              </li>
            ))}
        </ul>
      </footer>
      {open ? (
        <Modal
          id="footer-modal"
          isOpen={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          data-cookie-policy
        >
          <header data-policy-header>
            <h1>{policyData.title}</h1>
            <Button variation="transparent" onClick={() => setOpen(false)}>
              <IconComponent name="CloseOutlineIcon"  />
            </Button>
          </header>
          <p dangerouslySetInnerHTML={{
            __html: nl2br(policyData.body) || '',
          }}
          ></p>
        </Modal> ) : ( null )}
    </Fragment>

  );

};

export default Footer;
