import { useContext, createContext } from 'react';

import { useLegislation } from 'hooks';
import { LegislationState, ContextProps } from 'hooks/interfaces';

const LegislationContext = createContext<LegislationState>({} as LegislationState);

const LegislationContextProvider = ({ children }: ContextProps) => {
  const legislation = useLegislation();

  const updatedLegislation = {
    ...legislation,
    stateDispatch: legislation.dispatch,
    stateActions: legislation.actions,
    refetch: legislation.refetchLegislations,
    isLoading: legislation.loading,
  };

  return (
    <LegislationContext.Provider value={updatedLegislation}>
      {children}
    </LegislationContext.Provider>
  );
};

const useLegislationContext = () => useContext(LegislationContext);

export { LegislationContextProvider };
export default useLegislationContext;
