import { FormEditInput, FormGroup, IconComponent } from "components";
import { EditComponentProps } from "../interfaces";

import styles from './ClientDetailsEditTab.module.scss';

const ClientEditDetailsTab = ({ selectedIndex, project }: EditComponentProps) => {
  return (
    <section className={styles.root__client_edit_details} data-hidden={!(selectedIndex === 1)} data-create-content>
      <article data-logo>
        <span>Company logo</span>
        <div data-client-logo>
          <IconComponent name="ClientLogoIcon" />
        </div>
        <p>You can upload a JPG or PNG file. The max file size is 10mb.</p>
        <small>*Please make sure the client has approved the use of their logo.</small>
      </article>
      <FormGroup data-form-inputs data-edit-group="client_details">
        <FormEditInput
          name="name"
          type='text'
          label='project name'
          defaultValue={project?.name ?? ''}
          placeholder='Type your project name here'
          required
        />
        <FormEditInput
          name="description"
          type='text'
          label='project description'
          defaultValue={project?.description ?? ''}
          placeholder='Write a project description here'
          maxLength={500}
          rows={7}
          isTextArea
        />
        <FormEditInput
          name="domain"
          type='text'
          label='user email domain'
          subLabel={`Set up the client email domain, so that all the users within this domain get access to this client <br/>environment <strong data-email-domain>eg. @pwc.com</strong>`}
          defaultValue={project?.domain ?? ''}
          placeholder='@pwc.com'
          data-user-email-domain
          required
        >
          <span data-domain>@</span>
          <span>
            How will this domain be used? Based on the input provided regarding the clients domain, all the users that log in to the platform using that domain, will have access to this client environment. eg. if the domain is @pwc.com, all the pwc employees that log in using the PwC SSO will be redirected to the PwC legislation environment
          </span>
        </FormEditInput>

        <FormEditInput
          name="starting_date"
          type='date'
          label='starting date of engagement'
          defaultValue={project?.starting_date ?? ''}
          data-effective-until
        />
      </FormGroup>
    </section>
  );

};

export default ClientEditDetailsTab;
